import React, { useMemo, useState } from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    Elements
} from "@stripe/react-stripe-js";
// import './index.css'
import { useDispatch, useSelector } from "react-redux";
import { strings } from "../../helpers/translation";
import { loadStripe } from '@stripe/stripe-js';
import { Button } from "semantic-ui-react";
// import { functions } from "../../fire";
import { functions } from "firebase";
// import { AttachCard } from "../../helpers/helpers";


const useOptions = () => {
    const fontSize = '16px';
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: 'tfMedium',
                    "::placeholder": {
                        color: "#cacaca",
                        fontFamily: 'tfMedium,sans-serif',
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        }),
        [fontSize]
    );

    return options;
};

const SplitForm = (props) => {

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state.userProp);
    const { stripe_id } = useSelector(state => state.settings);

    const [state, setState] = useState({})
    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        setLoading(true)


        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement)
        });
        if (payload?.paymentMethod?.id) {
            console.log("payloadpayloadpayload", payload);
            // if (stripeId) {
            //     let attachPayment = await functions().httpsCallable('attachPayment')({
            //         paymentMethod: payload?.paymentMethod,
            //         customer_id: stripeId,
            //         uid
            //     });
            //     if (attachPayment?.data?.status === 'error') {
            //         setLoading(false)
            //         throw (attachPayment?.data)

            //     }
            // }
            let customerData = {};
            if (user?.info?.email) {
                customerData.email = user?.info?.email
            }
            if (user?.info?.number) {
                customerData.phone = user?.info?.counterCode + user?.info?.number
                customerData.name = user?.info?.name
            }
            var attachPayment = functions().httpsCallable('attachPayment');
            let customer_id = user?.info?.stripeCards?.find(r => r.stripe_account === stripe_id)?.user_stripeId
            let attached = await attachPayment({ customerData: customerData, paymentMethod: payload?.paymentMethod, customer_id: customer_id, uid: user?.id, stripe_id })
            console.log("attachedattachedattached", attached);
            setLoading(false)
            let { error } = attached?.data || {}
            if (error) {
                setState({ ...state, error: error.raw.message })
                setTimeout(() => {
                    setState({ ...state, error: false })
                }, 5000);
                return
            }
            // let attachPayment = await dispatch(AttachCard(user, {
            //     paymentMethod: payload?.paymentMethod,
            //     customer_id: user?.stripeId,
            //     customerData,
            //     uid: user?.uid,
            // }))
            // if (attachPayment?.status === 'error') {
            //     setLoading(false)
            //     throw (attachPayment?.message)
            // }

            // dispatch(UpdateUserCards(payload?.paymentMethod))
            setLoading(false)
            props.goBack(attached?.data?.card)
        } else {
            console.log("payloadpayload", payload.error);
            if (payload?.error) {

                setLoading(false)
                throw (payload?.error?.message)
            }
        }
        return
    };

    return (
        <div style={{ padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '90vh' }} >
            <div >
                <div style={{ padding: '6px' }}>
                    <div className='title' style={{ paddingBottom: '4px' }} >{strings?.["Card number"]}</div>
                    <div style={{ border: '1px solid #cacaca', borderRadius: '6px', padding: '8px' }} >

                        <CardNumberElement
                            options={options}
                            // onReady={() => {
                            //     console.log("CardNumberElement [ready]");
                            // }}
                            onChange={event => {
                                setState({ ...state, [event.elementType]: event.complete })
                            }}
                        // onBlur={() => {
                        //     console.log("CardNumberElement [blur]");
                        // }}
                        // onFocus={() => {
                        //     console.log("CardNumberElement [focus]");
                        // }}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }} >
                    <div style={{ width: '48%' }}>
                        <div fontSize={14} style={{ paddingBottom: '4px' }}>{strings?.["Expiration date"]}</div>
                        <div style={{ border: '1px solid #cacaca', borderRadius: '6px', padding: '8px' }} >
                            <CardExpiryElement
                                options={options}
                                onChange={event => {
                                    setState({ ...state, [event.elementType]: event.complete })
                                }}
                            />
                        </div>

                    </div>
                    <div style={{ width: '48%' }}>
                        <div fontSize={14} style={{ paddingBottom: '4px' }}>CVV</div>
                        <div style={{ border: '1px solid #cacaca', borderRadius: '6px', padding: '8px' }} >
                            <CardCvcElement
                                options={options}
                                onChange={event => {
                                    setState({ ...state, [event.elementType]: event.complete })
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
            {
                state?.error && <div className='error'>{state?.error}</div>
            }

            <div className='cartDetail' >
                <Button
                    loading={loading}
                    onClick={() => {
                        handleSubmit()
                    }}
                    className='placeOrderBtn'
                >
                    {strings.addNewCard}
                </Button>
            </div>

            {/* <ActionBtn loading={loading} disabled={!stripe || !(state?.cardNumber && state?.cardExpiry && state?.cardCvc)} title={strings?.['Add Card']} onPress={() => {
                handleSubmit()
            }} /> */}
        </div>
    );
};
const Index = (props) => {
    const { stripe_id } = useSelector(state => state.settings);
    const stripePromise = loadStripe(process.env.REACT_APP__STRIPE, {
        stripeAccount: stripe_id
    });
    return (
        <Elements stripe={stripePromise}>
            <SplitForm {...props} />
        </Elements>
    );
};

export default Index;
