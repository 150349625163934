import { createAction } from 'redux-action';
import { auth, firestore } from 'firebase'
export const USERPROPERTY = 'USERPROPERTY';
export const USERLOCATION = 'USERLOCATION';
export const TIMINGS = 'TIMINGS';
export const USERADDRESS = 'USERADDRESS';
export const ALL_LOCATIONS = 'ALL_LOCATIONS';
export const ACTIVE_ORDER = 'ACTIVE_ORDER';
export const GET_MENU = 'GET_MENU';
export const LAST_RES = 'LAST_RES';
export const HELPER_PROPS = 'HELPER_PROPS';
export const UPDATE_LOYALTY_POINTS = 'UPDATE_LOYALTY_POINTS';
export const SEND_NOTIF = 'SEND_NOTIF';


export const updateUserProperty = createAction(USERPROPERTY, (prop) => {
  return prop
  // return fetchUser().then(user => {
  //   return user
  // }).catch(err => console.log('LOG ::: err ::: ', err));

})
export const updateTimings = createAction(TIMINGS, (prop) => {
  console.log("proppropprop",prop);
  return prop

})
export const updateUserLocation = createAction(USERLOCATION, (prop) => {
  return prop

})
export const updateUserAddress = createAction(USERADDRESS, (prop) => {
  return prop

})
export const updateLocationsList = createAction(ALL_LOCATIONS, (prop) => {
  return prop

})
export const setActiveOrder = createAction(ACTIVE_ORDER, (prop) => {
  return prop

})
export const persistMenu = createAction(GET_MENU, (prop) => {
  return prop

})
export const updateRes = createAction(LAST_RES, (prop) => {
  return prop

})
export const setHelpers = createAction(HELPER_PROPS, (prop) => {
  return prop

})
export const sendNotif = createAction(SEND_NOTIF, (prop) => {
  return prop

})

const initialState = {
  // user: {},
};

export default (state = initialState, action) => {

  // console.log("initialState",initialState.user)
  switch (action.type) {
    case USERPROPERTY:
      return {
        ...state,
        user: action.payload
      };
    case USERLOCATION:
      return {
        ...state,
        location: action.payload
      };
    case TIMINGS:
      return {
        ...state,
        timings: action.payload
      };
    case ACTIVE_ORDER:
      return {
        ...state,
        activeOrder: action.payload
      };
    case GET_MENU:
      return {
        ...state,
        menu: action.payload.menu,
        menuMap: action.payload.menuMap,
        categoryMenu: action.payload.categoryMenu,
      };
    case LAST_RES:
      return {
        ...state,
        lastRes: action.payload
      };
    case ALL_LOCATIONS:
      return {
        ...state,
        allLocations: action.payload
      };
    case USERADDRESS:
      return {
        ...state,
        userAddressProp: action.payload
      };
    case HELPER_PROPS: {
      return {
        ...state,
        helpers: action.payload
      };
    }
    case SEND_NOTIF: {
      return {
        ...state,
        notif: action.payload
      };
    }
    default:
      return state;
  }
};