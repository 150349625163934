import React, { Component } from 'react';
import './selectLocation.css'
import { Dimmer, Input, Loader, Accordion, Icon, Button, Placeholder } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { flushOrders } from '../../../reducers/newOrder'
import { updateUserLocation, updateLocationsList, updateTimings } from '../../../reducers/userReducer'
import { firestore } from 'firebase'
import QRcode from './img/qr-img.svg'
import { Link, Redirect } from 'react-router-dom'
import Header from '../../Header/index'
import axios from 'axios';
import { strings } from '../../../helpers/translation';
function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1 && s2 && s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength === 0) {
        return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}
function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();
    // eslint-disable-next-line
    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
            if (i === 0)
                costs[j] = j;
            else {
                if (j > 0) {
                    var newValue = costs[j - 1];
                    if (s1.charAt(i - 1) !== s2.charAt(j - 1))
                        newValue = Math.min(Math.min(newValue, lastValue),
                            costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0)
            costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}
function distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") { dist = dist * 1.609344 }
        if (unit === "N") { dist = dist * 0.8684 }
        return dist;
    }
}
class SelectLocation extends Component {
    state = {
        loading: true,
        collapse: true,
        itemClicked: null,
        areasObjects: {},
        type: 'delivery',
        loadingFetaure: true,
        // activeIndex: 0
        direction: localStorage.getItem('direction'),
        filteredAreasObjects: false,

    }
    componentDidMount() {
        const { settings } = this.props
        document.body.style.overflowY = 'hidden'
        setTimeout(() => {
            this.setState({ loadingFetaure: false })
        }, 2500)
        if (settings?.resKey) {
            this.loadView()
        }
    }
    componentWillUnmount() {
        document.body.style.overflowY = 'auto'

    }

    componentDidUpdate(prevProps, state) {
        const { settings } = this.props
        if (settings?.resKey && settings !== prevProps?.settings) {
            console.log("settingssettingssettings", settings);
            this.loadView()
            return true
        }
        return null
    }

    loadView = () => {
        document.body.style.overflowY = 'hidden'
        const { settings } = this.props
        let { direction } = this.state
        var url = new URL(window.location.href);
        var branch = url.searchParams.get("branch") || this.props.branch;
        let _this = this
        var areaRef = firestore().collection("resturant").doc(settings?.resKey).collection("locations");
        let allAreas = []
        let areasObjects = {}
        areaRef.get().then(function (querySnapshot) {
            if (querySnapshot.empty) {
                return
            } else {
                querySnapshot.forEach(function (doc) {
                    if (doc.data().branches) {
                        Object.keys(doc.data().areas).map((area, index1) => {
                            let areasList = ((doc.data().areas)[area].list) ? ((doc.data().areas)[area].list) : ((doc.data().areas)[area]);
                            let areaName = ((doc.data().areas)[area]).name;
                            let areaName_ar = ((doc.data().areas)[area]).name_ar;
                            let localizedKey = direction === 'rtl' ? 'name_ar' : 'name';

                            return areasList.map((location, index2) => {
                                if (areasList.includes(location)) {
                                    let locationObject = { location: location, fees: doc.data().fees, branches: doc.data().branches, minOrder: doc.data().minOrder, deliveryTime: doc.data().deliveryTime, locationId: doc.id, areaName, areaName_ar }
                                    allAreas.push({ key: Math.random(), value: location, text: location, fees: doc.data().fees, city: area, branches: doc.data().branches, minOrder: doc.data().minOrder, deliveryTime: doc.data().deliveryTime, locationId: doc.id, areaName, areaName_ar })

                                    if (areasObjects[area]) {
                                        areasObjects[area].push(locationObject)
                                        areasObjects[area] = areasObjects[area].sort((a, b) => {
                                            if (a.location[localizedKey] > b.location[localizedKey]) {
                                                return 1;
                                            }
                                            if (b.location[localizedKey] > a.location[localizedKey]) {
                                                return -1;
                                            }
                                            return 0;
                                        })
                                    } else {
                                        areasObjects[area] = [locationObject].sort((a, b) => {
                                            if (a.location[localizedKey] > b.location[localizedKey]) {
                                                return 1;
                                            }
                                            if (b.location[localizedKey] > a.location[localizedKey]) {
                                                return -1;
                                            }
                                            return 0;
                                        })
                                    }
                                }
                                return false
                            })
                        })
                    }
                    let sorted = (Object.keys(areasObjects)).map(item => {
                        let title_ar = areasObjects[item] && areasObjects[item][0] && areasObjects[item][0].areaName_ar;
                        return { title: item, title_ar, areas: areasObjects[item] }
                    })
                    let localizedKey = direction === 'rtl' ? 'title_ar' : 'title';
                    sorted = sorted.sort((a, b) => {
                        if (a[localizedKey] > b[localizedKey]) {
                            return 1;
                        }
                        if (b[localizedKey] > a[localizedKey]) {
                            return -1;
                        }
                        return 0;
                    })

                    allAreas = allAreas.sort((a, b) => {
                        if (a.text.name > b.text.name) {
                            return 1;
                        }
                        if (b.text.name > a.text.name) {
                            return -1;
                        }
                        return 0;
                    })
                    _this.props.updateLocationsList(allAreas);
                    let activeIndex = Object.keys(areasObjects).length === 1;
                    _this.setState({ areasObjects: sorted, loading: false, activeIndex: activeIndex ? 0 : false })
                });
            }
        });

        firestore().collection("resturant").doc(settings?.resKey).get().then(results => {
            let data = results.data()
            let { branches } = data
            let featuredImages = []
            if (data && branches) {
                let branchesList = Object.keys(branches).map(key => {
                    if (branches[key].pictures) {
                        featuredImages = featuredImages.concat(branches[key].pictures)
                    }
                    return {
                        name: branches[key].name,
                        key: key,
                        activeMenu: branches[key].activeMenu,
                        active: branches[key].active,
                        address: branches[key].address,
                        address_ar: branches[key].address_ar,
                        estimatedTime: branches[key].estimatedTime ? branches[key].estimatedTime : 0,
                        name_ar: branches[key].name_ar,
                        timings: branches[key]?.timings || {}
                    }
                })

                let selectedBranch = branchesList.filter(item => item.key === branch)[0]
                if (selectedBranch) {
                    selectedBranch.type = 'dineIn'
                    selectedBranch.fees = '0'
                    selectedBranch.branches = [branch]
                    selectedBranch.minOrder = 0
                    selectedBranch.deliveryTime = false
                    selectedBranch.locationId = false

                }

                this.setState({
                    featuredImages,
                    branchesList,
                    type: (selectedBranch && settings?.deliveryMode?.includes('dineIn')) ? 'dineIn' : ((settings?.deliveryMode && settings?.deliveryMode?.length > 0) ? settings?.deliveryMode[0] : 'delivery'),
                    selectedBranch: settings?.deliveryMode?.includes('dineIn') ? selectedBranch : false,
                    loading: false,
                }, () => {
                    if (selectedBranch && settings?.deliveryMode?.includes('dineIn') && !settings?.interactiveDinein) {
                        this.selectLocation(selectedBranch)
                    }
                })
            }
        })

    }

    showPosition(position) {
        this.setPosNames(position.coords.latitude, position.coords.longitude)
    }
    setPosNames = (lat, lng) => {
        const { settings } = this.props
        let { areasObjects, direction } = this.state
        let _this = this
        // let lat = 25.3356543
        // let lng = 55.3885463
        axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
                latlng: lat + ',' + lng,
                key: 'AIzaSyAPe5JQQz0a1T7ytukrW3JLTapYdfRyuSE',
                language: 'en',
                libraries: 'places'
            }
        }).then(function (response) {
            if (response.data.results && response.data.results[1] && response.data.results[1]['address_components']) {
                let allRes = response.data.results;
                let neighborhood = false;
                let area = ''
                let address = ''
                let labelAddress = ''

                for (let r = 0; r < allRes.length; r++) {
                    let allResmin = allRes[r]['address_components'];
                    address = allRes[0]['formatted_address']
                    // console.log("addressaddress", address)
                    // console.log("allResmin", allResmin)

                    for (let k = 0; k < allResmin.length; k++) {
                        if (allResmin[k].types.indexOf("administrative_area_level_2") >= 0) {
                            if (settings?.counterCode === '20') {
                                labelAddress = allResmin[k]['long_name']
                            }
                        }
                        if (!neighborhood) {
                            if (allResmin[k].types.indexOf("administrative_area_level_1") >= 0) {
                                area = allResmin[k]['long_name'];
                                if (settings?.counterCode === '971') {
                                    labelAddress = allResmin[k]['long_name']
                                }
                            }
                        }
                        if (allResmin[k].types.indexOf("neighborhood") >= 0) {
                            neighborhood = true;
                            area = allResmin[k]['long_name'];
                        }
                    }
                }
                let filteredArray = []
                let localizedKey = direction === 'rtl' ? 'name_ar' : 'name';

                areasObjects.forEach(item => {
                    item.areas.forEach(record => {
                        let addresGeo = record.location && record.location.location && record.location.location.split(",")
                        let match = false;
                        ([...address.split(address.includes(" - ") ? " - " : ','), area]).forEach(rec => {

                            if (similarity(rec, record.location.name) > 0.7) {
                                match = true
                            }
                        })
                        if (
                            match ||
                            (addresGeo && distance(lat, lng, ...addresGeo, 'K') < 2)
                        ) {
                            filteredArray.push(record)
                        }
                    })

                })
                let newAreas = {}
                filteredArray.forEach(locationObject => {
                    let { areaName } = locationObject
                    if (newAreas[areaName]) {
                        newAreas[areaName].push(locationObject)
                        newAreas[areaName] = newAreas[areaName].sort((a, b) => {
                            if (a.location[localizedKey] > b.location[localizedKey]) {
                                return 1;
                            }
                            if (b.location[localizedKey] > a.location[localizedKey]) {
                                return -1;
                            }
                            return 0;
                        })
                    } else {
                        newAreas[areaName] = [locationObject].sort((a, b) => {
                            if (a.location[localizedKey] > b.location[localizedKey]) {
                                return 1;
                            }
                            if (b.location[localizedKey] > a.location[localizedKey]) {
                                return -1;
                            }
                            return 0;
                        })
                    }
                })
                let sorted = (Object.keys(newAreas)).map(item => {
                    let title_ar = newAreas[item] && newAreas[item][0] && newAreas[item][0].areaName_ar;
                    return { title: item, title_ar, areas: newAreas[item] }
                })
                sorted = sorted.sort((a, b) => {
                    if (a[localizedKey] > b[localizedKey]) {
                        return 1;
                    }
                    if (b[localizedKey] > a[localizedKey]) {
                        return -1;
                    }
                    return 0;
                })
                _this.setState({ filteredAreasObjects: sorted, activeIndex: sorted.length > 0 ? 0 : false, area, loading: false, labelAddress: [area, labelAddress].join(' - '), address: ([area, address].join(' - ')), location: { lng, lat } })
            }

        }).catch(function (error) {
            console.log("error", error)
            _this.setState({ error: "Geolocation is not supported by this browser", loading: false })

        });
    }
    showError(error) {
        let { direction } = this.state
        let message = 'An unknown error occurred.'
        switch (error.code) {
            case error.PERMISSION_DENIED:
                message = direction === 'rtl' ? 'تم رفض الإذن للوصول إلى موقعك ، يرجى التحقق من إعدادات الخصوصية والمحاولة مرة أخرى' : "Permission denied to access your location,Please check privacy settings and try again"
                break;
            case error.POSITION_UNAVAILABLE:
                message = "Location information is unavailable."
                break;
            case error.TIMEOUT:
                message = "The request to get user location timed out."
                break;
            default:
                break;
        }
        console.log("error", error)
        alert(message)
        this.setState({ error: message, loading: false })
    }
    render() {
        let { loading, redirect } = this.state
        const { settings } = this.props
        if (redirect) {
            return (<Redirect to={(settings?.pathName ? ('/' + settings?.pathName) : '/')} />)
        }
        return (
            <Dimmer active className='selectLocationClass' page>
                {
                    loading ?
                        <Loader active />
                        :
                        this.renderStep()
                }
            </Dimmer>
        )
    }


    renderStep() {
        let { direction, search, activeIndex, type, branchesList, searchActive, selectedBranch, loadingFetaure, featuredImages, filteredAreasObjects } = this.state
        const { settings } = this.props
        // this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');

        let areasObjects = Object.assign([], this.state.areasObjects)
        if (search) {
            let filterd = []
            areasObjects.map((key, index) => {
                let filteredAreas = key.areas.filter(area => {
                    if (area.location.name.toLowerCase().includes(search) || area.location.name_ar.toLowerCase().includes(search)) {
                        return (area.location.name.toLowerCase().includes(search) || area.location.name_ar.toLowerCase().includes(search))
                    }
                    return false
                })
                if (filteredAreas && filteredAreas.length) {

                    filterd.push({ title: key.title, areas: filteredAreas })
                    if (!activeIndex) {
                        activeIndex = filterd.length - 1
                    }
                }
                return false
            })
            areasObjects = filterd
        }
        if (filteredAreasObjects) {
            areasObjects = Object.assign([], filteredAreasObjects)
        }
        return (
            <div className='popContainer' >
                <Header
                    updateLang={(direction) => {
                        strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
                        areasObjects = areasObjects.map(area => {
                            let key = direction === 'rtl' ? 'name_ar' : 'name'
                            area.areas = area.areas.sort((a, b) => {
                                if (a.location[key] > b.location[key]) {
                                    return 1;
                                }
                                if (b.location[key] > a.location[key]) {
                                    return -1;
                                }
                                return 0;
                            })
                            return area

                        })
                        areasObjects = areasObjects.sort((a, b) => {
                            let key = direction === 'rtl' ? 'title_ar' : 'title'
                            if (a[key] > b[key]) {
                                return 1;
                            }
                            if (b[key] > a[key]) {
                                return -1;
                            }
                            return 0;
                        })
                        this.setState({ direction, areasObjects }, () => {
                            window.scrollTo({
                                top: 0,
                            });
                            this.props.updateLang(direction)
                        })
                    }}
                    direction={direction}
                />

                {
                    (settings.featuredImage || (featuredImages && featuredImages.length > 0)) ? <div style={{ position: 'relative' }}>
                        {
                            loadingFetaure && <Placeholder fluid style={{ position: 'absolute', top: '0px', width: '100%', height: '100vh', zIndex: '99999' }} >
                                <Placeholder.Image rectangular />
                            </Placeholder>
                        }
                        <div className='featuredImg'
                            // linear-gradient(${direction === 'rtl' ? 'to top left' : 'to top right'}, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.10) , rgba(0, 0, 0, 0) 100%),
                            style={{ backgroundImage: ` url(${(featuredImages && featuredImages.length > 0) ? featuredImages[0] : settings?.featuredImage})` }}
                        >
                            {/* <img src={ `${(featuredImages && featuredImages.length > 0) ? featuredImages[0] : window.featuredImage}`}/> */}
                        </div>
                    </div>
                        :
                        ''
                }
                {
                    settings?.deliveryMode?.length ?
                        <div className='tagsContainer' >
                            <div className='tagsView'>
                                {
                                    settings?.deliveryMode?.map((item, index) => {
                                        return <Button key={index} attached={direction ? (index === 0 ? 'right' : 'left') : (index === 0 ? 'left' : 'right')} basic active={type === item} className='select tag' style={{ fontWeight: direction === 'rtl' ? '700' : '' }} onClick={() => this.setState({ type: item })}>
                                            {strings[item]}
                                        </Button>
                                    })
                                }
                            </div>
                        </div>
                        :
                        ''
                }
                {
                    type === 'delivery' ?
                        <div >
                            <div className='searchContainer' style={direction === 'rtl' ? (searchActive ? { marginLeft: '16px' } : { marginLeft: '0' }) : (searchActive ? { marginRight: '16px' } : { marginRight: '0' })}>
                                {
                                    searchActive ?
                                        <Input icon="search" iconPosition="left" placeholder={strings.searchYourArea} onChange={(e, { value }) => this.setState({ search: value.toLocaleLowerCase(), activeIndex: false })} />
                                        :
                                        <div style={{ display: 'block', textAlign: 'initial', width: '100%' }}>
                                            <div>
                                                {strings.SelectYourArea
                                                }
                                            </div>
                                            <div className='currentLocation'>
                                                {
                                                    this.state.filteredAreasObjects ?
                                                        <div style={{ display: 'flex', width: '100%' }} onClick={() => this.setState({ filteredAreasObjects: false, searchActive: false })}>
                                                            <div style={{ maxWidth: '85%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                                {this.state.labelAddress}
                                                            </div>
                                                            <span><Icon style={{ fontSize: '14px', color: 'lightgray', margin: '0 8px' }} name='close'></Icon></span>
                                                        </div>
                                                        :
                                                        < div onClick={() => {
                                                            let _this = this
                                                            this.setState({ loading: true }, () => {
                                                                if (navigator.geolocation) {
                                                                    navigator.geolocation.getCurrentPosition(_this.showPosition.bind(_this), _this.showError.bind(_this));
                                                                } else {
                                                                    alert('erroe')
                                                                    console.log("Geolocation is not supp")
                                                                    // _this.setState({ error: "Geolocation is not supported by this browser", loading: false })
                                                                }
                                                            })

                                                        }}>
                                                            {strings.chooseCurrentLocation} < span ><Icon name='location arrow icon'></Icon></span>
                                                        </div>
                                                }

                                            </div>
                                        </div>

                                }
                                <Button style={{ background: searchActive ? 'var(--themeColor)' : 'white', color: searchActive ? 'white' : 'var(--themeColor)', margin: '8px', fontSize: '16px' }} icon={searchActive ? 'cancel' : 'search'}
                                    onClick={() => this.setState({ searchActive: !searchActive, search: false, filteredAreasObjects: false })}></Button>

                            </div >
                            <div style={{ width: '100%', background: '#cacaca50', height: '4px' }} />

                            <div className='locationsContainer'>
                                <Accordion>
                                    {
                                        areasObjects.map((item, index) => {
                                            return (
                                                <ul key={index} className='locationsContainer2'>
                                                    <Accordion.Title
                                                        active={activeIndex === index}
                                                        index={0}
                                                        key={index}
                                                        className='locationTitleContainer'
                                                        onClick={() => this.setState({ activeIndex: activeIndex === index ? false : index })}
                                                    >
                                                        <div className='locationTitle'>{direction === 'rtl' && item.title_ar ? item.title_ar : item.title}</div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div>{item.areas.length}</div>

                                                            <Icon style={{ fontSize: '16px' }} name={activeIndex === index ? "angle up" : "angle down"}></Icon>

                                                        </div>

                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex === index} className='full-width'>
                                                        {
                                                            item.areas.map((area, index2) => {
                                                                return (
                                                                    <div
                                                                        key={index2}
                                                                        style={{ borderTop: '1px solid rgba(235, 235, 235, 1)', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '14px 16px' }}
                                                                        onClick={() => this.selectLocation(area)}
                                                                    >
                                                                        <div className='locationItem' style={{ border: 'none', padding: '0' }}>{area.location.name ? (direction === 'rtl' && area.location.name_ar ? area.location.name_ar : area.location.name) : area.location}</div>
                                                                        <Icon
                                                                            style={{ color: 'var(--themeColor)', fontSize: '16px', }}
                                                                            name={direction === 'rtl' ? "angle left" : 'angle right'}></Icon>

                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Accordion.Content>
                                                </ul>
                                            )
                                        })
                                    }

                                </Accordion>
                                {
                                    filteredAreasObjects && filteredAreasObjects.length === 0 ?
                                        <div style={{ padding: '30% 0' }}>
                                            <div style={{ color: '#cacaca', fontSize: '14px', fontWeight: 400 }}>{strings.cantFindLocation}</div>
                                            <div
                                                style={{ color: 'var(--themeColor)', textDecoration: 'underline', padding: '8px', cursor: 'pointer' }}
                                                onClick={() => this.setState({ filteredAreasObjects: false })}
                                            >{strings.locationList}</div>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div >
                        : type === 'dineIn' ?
                            selectedBranch ?
                                <div>
                                    <div className='searchContainer' style={{ minHeight: "57.5px" }}>
                                        {strings.selectTable}
                                    </div>
                                    <div className='locationsContainer' style={{ direction: direction, paddingBottom: '160px' }}>
                                        <div className='locationsContainer2' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 16px', borderBottom: '1px solid rgba(223, 223, 223, 1)' }}>
                                            <Input style={{ width: '100%', textAlign: direction === 'rtl' ? 'right' : 'left' }} transparent placeholder={strings.enterTableNumber} onChange={(e, { value }) => this.setState({ tableNumber: value })} />
                                        </div>
                                    </div>
                                    <div className='menuSection'>
                                        <div className='listContainer'>
                                            <div className='activeOrderContainer' style={{ backgroundColor: 'white' }}
                                                onClick={() => {
                                                    if (this.state.tableNumber) {
                                                        selectedBranch.location = this.state.tableNumber
                                                        this.selectLocation(selectedBranch)
                                                    }
                                                }}>
                                                <Button disabled={!this.state.tableNumber} className='CheckOut' style={{ width: '90%' }}>
                                                    <Link to={(settings?.pathName ? ('/' + settings?.pathName) : '/')} className='totalprice' style={{ margin: '0', fontSize: '18px' }}>{strings.letsOrder}</Link>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                :
                                <div style={{ padding: '70px 0' }}>
                                    <img src={QRcode} alt='' />
                                    <div className='qrcode'>
                                        <div className='qr-title'>{strings.scanQrCode}</div>
                                        <div className='qr-description'>{strings.qrDescription}</div>
                                    </div>
                                </div>
                            :
                            <>
                                <div className='searchContainer' style={{ minHeight: "57.5px" }}>
                                    {strings.pickupTitle}
                                </div>
                                <div className='locationsContainer' style={{ direction: direction }}>

                                    <ul className='locationsContainer2'>

                                        {
                                            branchesList.map((item, index) => {
                                                return (
                                                    <li
                                                        className='locationItem'
                                                        style={{ textTransform: 'capitalize', width: '100%' }}
                                                        key={index}
                                                        onClick={() => this.selectLocation(item)}
                                                    >
                                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <div style={{ width: '80%' }}>
                                                                <div style={{ fontWeight: 'bolder' }}>
                                                                    {direction === 'rtl' && item.name_ar ? item.name_ar : item.name}
                                                                </div>
                                                                <div style={{ padding: '6px 0', color: 'gray' }}>
                                                                    {direction === 'rtl' && item.address_ar ? item.address_ar : item.address}
                                                                </div>
                                                            </div>
                                                            {
                                                                item.estimatedTime ? <div>
                                                                    {item.estimatedTime.value + " " + strings[item.estimatedTime.label]}
                                                                </div>
                                                                    : ''
                                                            }


                                                        </div>

                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </>
                }

            </div >
        )
    }

    selectLocation(area) {
        let { type, branchesList } = this.state
        let { updateUserLocation, dismiss, flushOrders, updateTimings } = this.props;
        console.log("branchesListbranchesListbranchesList", branchesList);

        flushOrders()
        if (type === 'pickup') {
            let locationObject = { active: area.active || { active: true }, type: 'pickup', area: area.name, area_ar: area.name_ar, address: area.address, address_ar: area.address_ar, location: area.name, fees: "0", branches: [area.key], minOrder: 0, deliveryTime: false, activeMenu: area.activeMenu, locationId: area.locationId ? area.locationId : '' }
            updateUserLocation(locationObject)
            let { timings } = branchesList.find(r => r.key === area.key);
            updateTimings(timings)
            dismiss(area)
            return
        }
        if (type === 'dineIn') {
            console.log("###########", area);
            updateUserLocation(area);
            let { timings } = branchesList.find(r => r.key === area.key);
            updateTimings(timings)
            setTimeout(() => {
                let url = (window.location.pathname).split('/')
                let pathUrl = url[1]
                updateUserLocation(false)
                setTimeout(() => {
                    if (pathUrl && pathUrl !== 'dinein') {
                        window.location.href = '/' + pathUrl
                    } else {
                        window.location.href = '/'
                    }
                }, 200)
                //3600000
            }, 3600000)
            this.setState({ redirect: true }, () => {
                dismiss(area)

            })
            return
        }
        area.type = type
        let active = branchesList?.find(item => area?.branches?.includes(item?.key));
        console.log("activeactiveactive", active)
        if (active) {
            area.activeMenu = active?.activeMenu
            area.active = active?.active
            // area.location = area.location.name ? (direction === 'rtl' ? area.location.name_ar : area.location.name) : area.location;
            updateUserLocation(area)
            let { timings } = active;
            updateTimings(timings)

            this.setState({ redirect: true }, () => {
                dismiss(area)

            })
        } else {
            alert('we are not delivering to this area at this time')
        }

    }
}
function mapStateToProps({ userProp, settings }) {
    return {
        userProp,
        settings
    }
}

const mapDispatchToProps = dispatch => ({
    flushOrders: () => dispatch(flushOrders()),
    updateUserLocation: (location) => dispatch(updateUserLocation(location)),
    updateLocationsList: (locations) => dispatch(updateLocationsList(locations)),
    updateTimings: (timings) => dispatch(updateTimings(timings))
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectLocation)
